import React from 'react'
import PropTypes from 'prop-types'
import {Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const ImmunityPageTemplate = ({ title, pageId, previous, next, comments, content, contentComponent }) => {
  const PageContent = contentComponent || Content
  var titleString = title;

  if (pageId===6) {
    titleString = <h2 className="title is-size-3 has-text-weight-bold is-bold-light">6. Making the IMMUNITY<span style={{fontSize:"20px",position:"relative", top:"-10px"}}>&trade;</span> Meal Plan</h2>;
  }

  else {
    titleString = <h2 className="title is-size-3 has-text-weight-bold is-bold-light">{title}</h2>;;
  }

  return (

    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div style={{marginLeft: "25px"}}><a href="/immunity/">The Whole Foods Immunity Theory</a><a href="# " style={{color: "black"}}> > {title}</a></div>
            <div className="section">
              <h3 className="title is-size-3x has-text-weight-bold is-bold-light">
                {titleString}
              </h3>
              <PageContent className="content" content={content}/>

{pageId===7 &&

              <div className="has-text-centered"  style={{align: 'center', textAlign: 'center', padding: '20px'}}>
              <Link className="btn" to="/#help" style={{marginTop: "0px", paddingTop: "9px", width: "210px", boxShadow: "none", backgroundColor: "#00693eff", color: "white"}}>
                How Can I Help?
              </Link>
              </div>
}


              <div className="columns">
              <table border="0" width="100%"><tbody><tr><td style={{align: 'left'}}>
              <div style={{textAlign: 'left', backgroundColorx: "#800000", positionx: "relative", top:"0px"}} className="column is-2x ef-button-report-effectivenessx">
              <Link className="btn" to={previous} style={{marginTop: "0px", paddingTop: "9px", width: "110px", boxShadow: "none", backgroundColor: "#002366ff", color: "white"}}>
                 Previous
              </Link>
              </div>
              </td>
              <td style={{align: 'right'}}>

{title!=="7. The Solution" &&
              <div style={{align:'right', textAlign: 'right', backgroundColorx: "#800000", positionx: "relative", top:"0px"}} className="column is-2x ef-button-report-effectivenessx">
              <Link className="btn" to={next} style={{marginTop: "0px", paddingTop: "9px", width: "110px", boxShadow: "none", backgroundColor: "#002366ff", color: "white"}}>
                Next
              </Link>
              </div>
}

              </td>
              </tr>
              </tbody>
              </table>
              </div>


              <div>
  <h4 className="title is-size-3">Comments</h4>
  <div>

  <Link className="btn" to={"/forms/comment?page_id=" + pageId} style={{marginTop: "10px", paddingTop: "9px", width: "210px", paddingx: "10px", boxShadow: "none", backgroundColor: "#ff0000", color: "white"}}>
    Comment
  </Link>
  <p><br></br></p>
  </div>


              {comments && comments.length ? (
                <div style={{ marginTop: `0rem` }}>


                    {comments.map(comment => (
                      <div>
                      {comment.level===1 && <span><b>{comment.member}:</b><br></br><p>{comment.text}</p>
                                                <Link className="btn" to={"/forms/comment?page_id=" + pageId + "&comment_id=" + comment.commentId} style={{marginTop: "5px", paddingTop: "8px", width: "70px", boxShadow: "none", backgroundColor: "#1b99d5", color: "white"}}>
                                                  Reply
                                                </Link><p><br></br></p></span>}
                      {comment.level===2 && <span className="ef-comment-reply-2"><b>{comment.member}:</b><br></br><p className="ef-comment-reply-2">{comment.text}</p>
                                                <Link className="btn ef-comment-reply-2" to={"/forms/comment?page_id=" + pageId + "&comment_id=" + comment.commentId} style={{marginTop: "5px", paddingTop: "9px", paddingx:"5px", width: "70px", boxShadow: "none", backgroundColor: "#1b99d5", color: "white"}}>
                                                  Reply
                                                </Link><p><br></br></p></span>}
                      {comment.level===3 && <span className="ef-comment-reply-3"><b>{comment.member}:</b><br></br><p className="ef-comment-reply-3">{comment.text}</p>
                                                <p><br></br></p></span>}
                        </div>
                    ))}

                </div>
              ) : null}
              </div>


            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

ImmunityPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  pageId: PropTypes.number,
  previous: PropTypes.string,
  next: PropTypes.string,
  comments: PropTypes.array,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const ImmunityPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout pageMetadata = {post.frontmatter.pageMetadata}>
      <ImmunityPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        pageId={post.frontmatter.pageId}
        previous={post.frontmatter.previous}
        next={post.frontmatter.next}
        comments={post.frontmatter.comments}
        content={post.html}
      />
    </Layout>
  )
}

ImmunityPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ImmunityPage

export const immunityPageQuery = graphql`
  query ImmunityPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        pageMetadata {
          title
          description
        }
        title
        pageId
        previous
        next
        comments{
          member
          text
          commentId
          parentCommentId
        }
      }
    }
  }
`
